@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: "Poppins400", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.not-found-container {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  height: 100%;
}

.not-found-img {
  width: 350px;
}


@font-face {
  font-family: "Poppins900";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins800";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins700";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins600";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins500";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins400";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins300";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins200";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins100";
  src: local("Poppins"),
   url("./assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

.ant-drawer-title {
  color: white;
}

.contract {
  color: grey;
}
.buddycontainer{
  margin: 1em;

}
.buddycontainer button{
  margin: 3px;
  padding: 4px;
  border-radius:4px ;
  border-width: 1px;
  background: white;
  

}
.venueButton{
  border: 0px;
  border-radius:6px ;
  font-size: 10px;
  align-items: center;
}
.Table. EXPAND_COLUMN{
 width: 20px;

}


tr.ant-table-expanded-row > td{
  background-color: #fafaf3
}
.active{
background-color:#052e67 !important;
border-color: blue;
color: white;
font-weight: 900;
}
.inactive{
  background-color: white;
}
.shareView{
  float:right;
  cursor:default;
  color: black;
  pointer-events: none;
  font-weight: 950;
}
.downloadtable{
  margin: 14px;
}

.collection-custom-table thead th {
  background-color: #ebebeb; /* Replace with your desired background color */
}


